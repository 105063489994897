import { FC, useEffect, useState } from 'react';
import { Text } from '@mantine/core';
import cx from 'classnames';
import { Message } from './types';
import { useAppContext } from '../../contexts/appContext';
import { OrbyLoader } from '../OrbyLoader';

export const ChatMessage: FC<{
    message: Message;
}> = ({ message }) => {
    const { user } = useAppContext();
    const userInitials = `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`;
    const type = message.role;

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <div
            className={cx('flex items-start gap-2 opacity-0 transition duration-500 ease-in', {
                'opacity-100': mounted,
            })}
        >
            {type === 'User' ? (
                <div className="flex size-6 items-center justify-center overflow-hidden rounded-full border border-navy-10 bg-primary text-xs text-navy-30">
                    {userInitials}
                </div>
            ) : (
                <div className="flex size-6 items-center justify-center">
                    <OrbyLoader variant="gray" />
                </div>
            )}
            <div
                className={cx('w-[70%] font-manrope font-medium', {
                    'text-navy-70': type === 'User',
                    'text-navy-50': type === 'AI',
                })}
                style={{ marginBottom: 8 }}
            >
                {message?.text_input?.map(text => (
                    <Text key={`${message.id}${message.role}${text}`}>{text}</Text>
                ))}
            </div>
        </div>
    );
};
