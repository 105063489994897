import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import Text, { CustomTextType } from '../text';

const SelectLabelWrapperDiv = styled.div`
    .caret-icon {
        visibility: hidden;
    }
    :hover .caret-icon {
        visibility: visible !important;
    }
`;

type Rest = React.HTMLAttributes<HTMLDivElement>;
const SelectLabelWrapper = React.forwardRef(
    (
        {
            label,
            children,
            textType = 'body/small',
            cleanDesign,
            className,
            textClassName,
            disabled,
            ...rest
        }: {
            children: JSX.Element;
            textType?: CustomTextType;
            label?: string | JSX.Element;
            cleanDesign?: boolean;
            textClassName?: string;
            disabled?: boolean;
        } & Rest,
        ref: React.Ref<HTMLDivElement>,
    ) => (
        <SelectLabelWrapperDiv
            ref={ref}
            className={classNames(className, {
                'flex gap-1 items-center': cleanDesign,
            })}
            {...rest}
        >
            {label && (
                <Text
                    type={cleanDesign ? '16Reg' : textType}
                    className={classNames('ml-0.5 mb-0.5 flex items-center', textClassName, {
                        '!text-pacific': cleanDesign && !disabled,
                        '!text-navy-50': !cleanDesign,
                        '!text-navy-70': disabled,
                    })}
                >
                    {label}
                </Text>
            )}
            {children}
        </SelectLabelWrapperDiv>
    ),
);

export default SelectLabelWrapper;
