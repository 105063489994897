/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { X } from 'phosphor-react';
import React, { FC, MouseEventHandler, ReactNode } from 'react';
import cx from 'classnames';
import { Tooltip, TooltipProps } from '@mantine/core';

interface ChipProps {
    children: ReactNode;
    contentClassName?: string;
    onRemove?: MouseEventHandler<HTMLButtonElement>;
    wrapperOnClick?: MouseEventHandler<HTMLDivElement>;
    wrapperClassName?: string;
    wrapperStyle?: React.CSSProperties;
    removeIconSize?: number;
    withTooltip?: boolean;
    tooltip?: ReactNode;
    tooltipProps?: Omit<TooltipProps, 'children' | 'label'>;
}

export const Chip: FC<ChipProps> = ({
    children,
    contentClassName,
    removeIconSize = 12,
    withTooltip,
    tooltip,
    tooltipProps,
    wrapperClassName,
    wrapperStyle,
    onRemove,
    wrapperOnClick,
}) => {
    const chip = (
        <div
            style={wrapperStyle}
            className={cx(
                'rounded ml-2 pl-2 pr-1 py-1 inline-flex items-center space-x-2 whitespace-nowrap bg-gradient-to-r from-[#466CF1] to-[#214EEC]',
                wrapperClassName,
            )}
            onClick={wrapperOnClick}
        >
            <div
                className={cx(
                    'text-xs font-medium text-white-80 hover:text-white-100',
                    contentClassName,
                )}
            >
                {children}
            </div>
            {onRemove && (
                <button
                    className="hover:bg-primary/20 group rounded-sm p-1"
                    type="button"
                    onClick={onRemove}
                >
                    <X
                        className="text-white-80 group-hover:!text-white-100"
                        size={removeIconSize}
                    />
                </button>
            )}
        </div>
    );

    if (withTooltip) {
        return (
            <Tooltip label={tooltip} {...tooltipProps}>
                {chip}
            </Tooltip>
        );
    }

    return chip;
};
