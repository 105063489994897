import * as d3 from 'd3';
import moment from 'moment';

import { formatNumber } from '../../utils/numberFormatHelpers';
import { grayScale, primaryColors } from '../../constants/colors';
import { DatasetValue } from '../types/timeSeriesChart';

export const isValidDate = (date: unknown): boolean => {
    if (!(date instanceof Date)) {
        return false;
    }

    return !isNaN(date.getTime());
};

export const renderDataset = ({
    dataset,
    lineGenerator,
    adjustedHeight,
    xScale,
    yScale,
    targetProjectionDate,
    svgGroup,
}) => {
    const renderLine = (data: DatasetValue[], isDotted: boolean = false) => {
        svgGroup
            .append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', dataset.color)
            .attr('stroke-width', 1.5)
            .attr('stroke-dasharray', isDotted ? '3,3' : null)
            .attr('d', lineGenerator)
            .attr('filter', dataset.useGlowEffect ? 'url(#glow)' : 'none');
    };

    const renderArea = () => {
        if (!dataset.useLinearGradient) {
            return;
        }

        const areaGenerator = d3
            .area<DatasetValue>()
            .x(d => xScale(moment(d.x).toDate()))
            .y0(adjustedHeight)
            .y1(d => yScale(d.y))
            .curve(d3.curveMonotoneX);

        svgGroup
            .append('path')
            .datum(dataset.values)
            .attr('fill', 'url(#area-gradient)')
            .attr('d', areaGenerator);
    };

    // draw dotted lines after target projection date
    if (isValidDate(targetProjectionDate)) {
        const dataBefore: DatasetValue[] = [];
        const dataAfter: DatasetValue[] = [];

        dataset.values.forEach(d => {
            const date = moment(d.x).toDate();

            if (date <= targetProjectionDate) {
                dataBefore.push(d);
            }

            if (date >= targetProjectionDate) {
                dataAfter.push(d);
            }
        });

        renderLine(dataBefore);
        renderLine(dataAfter, true);
    } else {
        renderLine(dataset.values);
    }

    renderArea();
};

export const drawTargetPriceLine = ({
    targetPrice,
    targetPriceAnnotation,
    targetPriceAnnotationPosition,
    adjustedWidth,
    yScale,
    svgGroup,
}) => {
    // draw the horizontal line
    svgGroup
        .append('line')
        .attr('class', 'horizontalLine')
        .attr('x1', 0)
        .attr('x2', adjustedWidth)
        .attr('y1', yScale(targetPrice))
        .attr('y2', yScale(targetPrice))
        .style('stroke', primaryColors['blue'])
        .style('stroke-width', 1)
        .style('stroke-dasharray', '3,3');

    // annotation
    if (targetPriceAnnotation) {
        const positions = {
            start: { x: 0, anchor: 'start', yOffset: -8 },
            middle: { x: adjustedWidth / 2, anchor: 'middle', yOffset: -8 },
            end: { x: adjustedWidth, anchor: 'end', yOffset: -8 },
        };

        const annotationConfig = positions[targetPriceAnnotationPosition] || positions['start'];

        svgGroup
            .append('text')
            .text(targetPriceAnnotation)
            .attr('class', 'lineAnnotation')
            .attr('x', annotationConfig.x)
            .attr('y', yScale(targetPrice) + annotationConfig.yOffset)
            .attr('text-anchor', annotationConfig.anchor)
            .attr('dominant-baseline', 'bottom')
            .style('font-size', '12px')
            .style('fill', grayScale[60]);
    }
};

export const onMouseMove = (
    event,
    { adjustedWidth, datasets, dots, tooltipGroup, verticalLine, xScale, yScale },
) => {
    const [xPos] = d3.pointer(event, this); // Get the x position of the mouse within the chart
    const hoveredDate = xScale.invert(xPos); // Convert that x position back to a date

    // Find the closest date in the dataset to the hovered date
    let closestDate = null;
    let closestDistance = Infinity;

    // Go through each dataset to find the point closest to the hovered date
    datasets.forEach(dataset => {
        dataset.values.forEach(point => {
            const pointDate = moment(point.x).toDate().getTime();
            const distance = Math.abs(pointDate - hoveredDate.getTime());

            if (distance < closestDistance) {
                closestDistance = distance;
                closestDate = pointDate;
            }
        });
    });

    // If a closest date is found, display the vertical line and dots at this position
    if (closestDate) {
        const closestX = xScale(closestDate);

        // Update the vertical line to match the x position of the closest data point's date
        verticalLine.attr('x1', closestX).attr('x2', closestX).style('display', null);

        // Update positions of dots to match the y-values of the closest date across datasets
        dots.data(
            datasets.map(dataset => {
                const index = dataset.values.findIndex(
                    point => moment(point.x).toDate().getTime() === closestDate,
                );
                return index >= 0 ? dataset.values[index] : null;
            }),
        )
            .attr('cx', closestX)
            .attr('cy', d => (d ? yScale(d.y) : 0))
            .style('display', d => (d ? null : 'none')); // Only display the dot if the dataset has a value for this date

        // Tooltip start
        // Calculate tooltip positioning
        let tooltipX = closestX + 10; // Offset to the right of the line
        const tooltipY = -20; // Fixed top position
        let alignLeft = true; // Flag to indicate text alignment

        // Adjust tooltip position if it goes beyond the chart boundaries
        const tooltipWidth = 60; // Ensure this matches or dynamically calculate based on content
        if (tooltipX + tooltipWidth > adjustedWidth) {
            tooltipX = closestX - tooltipWidth - 15; // Flip to the left side of the line
            alignLeft = false; // Align text to the right
        }

        // Update tooltip position and make it visible
        tooltipGroup.style('display', null).attr('transform', `translate(${tooltipX},${tooltipY})`);

        tooltipGroup.selectAll('*').remove();

        // add white background to tooltip
        const tooltipBackground = tooltipGroup
            .append('rect')
            .attr('class', 'tooltip-background')
            .attr('fill', 'white')
            .attr('rx', 4)
            .attr('ry', 4);

        // Header - Date
        tooltipGroup
            .append('text')
            .attr('class', 'tooltip-text')
            .attr('x', alignLeft ? 5 : tooltipWidth - 5)
            .attr('y', 25)
            .attr('text-anchor', alignLeft ? 'start' : 'end') // Adjust text anchor based on alignment
            .text(`${moment(closestDate).format('MMMM D')}`)
            .style('font-size', '10px')
            .attr('fill', grayScale[100]);

        // Display data for each dataset
        datasets.forEach((dataset, i) => {
            const dataPoint = dataset.values.find(
                point => moment(point.x).toDate().getTime() === closestDate,
            );
            if (dataPoint) {
                // Colored square
                tooltipGroup
                    .append('rect')
                    .attr('class', 'dataset-color-square')
                    .attr('x', alignLeft ? 5 : tooltipWidth - 10)
                    .attr('y', 33 + i * 15) // Position below the previous item
                    .attr('width', 5)
                    .attr('height', 5)
                    .attr('fill', dataset.color);

                tooltipGroup
                    .append('text')
                    .attr('class', 'tooltip-text')
                    .attr('x', alignLeft ? 15 : tooltipWidth - 15)
                    .attr('y', 40 + i * 15) // Increment y position for each dataset
                    .attr('text-anchor', alignLeft ? 'start' : 'end')
                    .text(
                        `${
                            dataPoint.y > 1000
                                ? formatNumber(dataPoint.y, '$0,0')
                                : formatNumber(dataPoint.y, '$0,0.00')
                        }`,
                    )
                    .style('font-size', '10px')
                    .attr('fill', grayScale[100]);
            }
        });

        const tooltipContent = tooltipGroup.node().getBBox();

        tooltipBackground
            .attr('width', tooltipContent.width + 10)
            .attr('height', tooltipContent.height + 10)
            .attr('x', tooltipContent.x - 5)
            .attr('y', tooltipContent.y - 5)
            .attr('stroke', grayScale[20])
            .attr('stroke-width', 1);
    } else {
        // Hide the vertical line and dots if no closest date is found
        verticalLine.style('display', 'none');
        dots.style('display', 'none');
        tooltipGroup.style('display', 'none'); // Hide tooltip if not close to any data point
    }
};
