import { Button as ButtonMantine, ButtonProps, ButtonStylesNames } from '@mantine/core';
import React, { ButtonHTMLAttributes, FC } from 'react';
import cx from 'classnames';
import StyledTooltip from '../components/utils/styledTooltip';

type ExtraButtonProps = {
    onClick?: () => void;
    disabledTooltipLabel?: string;
    variant?: 'primary' | 'secondary' | 'link';
    containerClassName?: string;
    id?: string;
    classNames?: Partial<Record<ButtonStylesNames, string>>;
};

export const Button: FC<
    ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps & ExtraButtonProps
> = ({
    variant = 'primary',
    disabledTooltipLabel,
    children,
    className,
    disabled,
    classNames,
    containerClassName,
    id,
    ...rest
}) => (
    <StyledTooltip
        label={disabledTooltipLabel}
        position="bottom"
        disabled={!disabled || rest?.loading || !disabledTooltipLabel}
    >
        <div
            className={cx(
                'rounded-full flex p-0.5 items-center justify-center w-fit group h-8',
                containerClassName,
                {
                    'bg-primary': variant === 'primary',
                    'border border-transparent active:border-primary active:bg-primary hover:border-primary bg-secondary':
                        variant === 'secondary',
                    '!p-0': variant === 'link',
                    'max-w-8': !children && (!rest?.leftSection || !rest?.rightSection),
                },
            )}
        >
            <ButtonMantine
                {...rest}
                id={id}
                disabled={disabled}
                classNames={{
                    section: cx(classNames?.section ?? '', 'text-base h-fit', {
                        '!text-xs': variant === 'link',
                    }),
                    inner: cx(classNames?.inner ?? '', 'flex gap-2 items-center justify-center', {
                        '!gap-0': !children,
                    }),
                    ...classNames,
                }}
                className={
                    cx(
                        className,
                        '!py-1 !px-3 !rounded-full min-w-7 min-h-7 transition-colors !text-xs font-semibold ease-in-out duration-100 disabled:!cursor-not-allowed',
                        {
                            '!px-[5px]': !children && (!rest?.leftSection || !rest?.rightSection),
                            'border-1 border-gray-light group-hover:border-columbia-blue-darker text-neutral-900':
                                variant === 'primary',
                            'text-primary group-active:text-secondary': variant === 'secondary',
                            '!p-2 text-primary group-hover:text-columbia-blue-dark !font-medium':
                                variant === 'link',
                        },
                    ) as string
                }
                loaderProps={{ type: 'dots' }}
                unstyled
            >
                {children}
            </ButtonMantine>
        </div>
    </StyledTooltip>
);
