import { Image, TextInput } from '@mantine/core';
import { Microphone, PaperPlaneTilt } from 'phosphor-react';
import { FC, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { colorScales } from '../../constants/colors';

const Icon = ({ onClick, disabled }) =>
    disabled ? (
        <PaperPlaneTilt width={17} height={17} className="min-h-4 min-w-4 text-gray-dark" />
    ) : (
        <button
            type="button"
            onClick={onClick}
            className="group size-4 cursor-pointer"
            aria-label="send"
        >
            <div className="group-hover:hidden ">
                <Image src="images/outlined_send_icon.svg" alt=">>>" />
            </div>
            <div className="hidden group-hover:block">
                <Image src="images/filled_send_icon.svg" alt=">>>" />
            </div>
        </button>
    );

const ChatInput: FC<{
    input: string;
    setInput: (input: string) => void;
    sendMessage: VoidFunction;
    disabled: boolean;
    title: string;
}> = ({ input, setInput, sendMessage, disabled, title = 'OneBrain' }) => {
    const { transcript, listening, browserSupportsSpeechRecognition, resetTranscript } =
        useSpeechRecognition();
    const startListening = () => SpeechRecognition.startListening({ continuous: true });

    useEffect(() => {
        if (listening) return;
        if (transcript.length > 0) {
            setInput(input + transcript);
            resetTranscript();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listening, resetTranscript, setInput, transcript]);

    return (
        <div className="flex items-center gap-2">
            <TextInput
                className="w-full grow py-4"
                placeholder={`Ask ${title}`}
                value={input + transcript}
                classNames={{ section: 'w-fit' }}
                rightSection={
                    <button
                        type="button"
                        disabled={!browserSupportsSpeechRecognition}
                        aria-label="Microphone"
                        className="mr-2.5"
                        onClick={() => {
                            if (listening) {
                                SpeechRecognition.stopListening();
                                return;
                            }
                            startListening();
                        }}
                    >
                        <Microphone
                            color={
                                listening
                                    ? colorScales.green[10]
                                    : (!browserSupportsSpeechRecognition &&
                                          colorScales.reddishPink[10]) ||
                                      undefined
                            }
                        />
                    </button>
                }
                disabled={disabled}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        sendMessage();
                    }
                }}
                onChange={e => setInput(e.currentTarget.value)}
            />
            <Icon
                onClick={() => {
                    sendMessage();
                }}
                disabled={(input + transcript).length === 0}
            />
        </div>
    );
};

export default ChatInput;
