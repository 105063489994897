import React, { FC, HTMLAttributeAnchorTarget, ReactNode } from 'react';
import NextLink from 'next/link';
import { Url } from 'next/dist/shared/lib/router/router';
import cx from 'classnames';
import { ArrowRight, LockSimple } from 'phosphor-react';

interface LinkProps {
    href: Url;
    children: ReactNode;
    disabled?: boolean;
    className?: string;
    size?: 'xs' | 'sm' | 'base';
    leftIcon?: ReactNode | null;
    rightIcon?: ReactNode | null;
    target?: HTMLAttributeAnchorTarget;
}
export const Link: FC<LinkProps> = ({
    href,
    children,
    disabled,
    className,
    size = 'xs',
    leftIcon,
    rightIcon = (
        <ArrowRight
            className={cx(className, {
                'text-navy-30': disabled,
                'text-navy-50 group-hover:text-navy-70': !disabled,
            })}
            size={16}
        />
    ),
    target,
}) => {
    const lockSimpleElement = disabled && <LockSimple className="text-navy-30" size={16} />;
    return (
        <NextLink
            className={cx('group flex gap-1 items-center', className, {
                'cursor-default pointer-events-none': disabled,
            })}
            href={href}
            target={target}
        >
            {!leftIcon && lockSimpleElement}
            {leftIcon}
            <span
                className={cx('font-medium', className, {
                    'text-xs': size === 'xs',
                    'text-sm': size === 'sm',
                    'text-base': size === 'base',
                    'text-navy-30': disabled,
                    'text-navy-50 group-hover:text-navy-70': !disabled,
                })}
            >
                {children}
            </span>
            {leftIcon && lockSimpleElement}
            {!leftIcon && rightIcon}
        </NextLink>
    );
};
