import { Drawer, Menu } from '@mantine/core';
import { useDebouncedValue, useViewportSize } from '@mantine/hooks';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { CaretDown, FunnelSimple, X } from 'phosphor-react';
import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile as isMobileBrowser } from 'react-device-detect';
import { Spinner } from '../Spinners';
import { replaceHtmlEntityNames } from '../util/replaceHtmlEntityNames';
import { useScroll } from '../../hooks/useScroll';
import { NewTypeSelectData, NewTypeSelectValues } from '../types/select/newTypeOfSelect';
import { filterData, reshapingData } from '../util/selects';
import { primaryColors } from '../../constants/colors';
import { RenderSelectedItems } from './renderSelectedItems';
import { DisplaySelectedMain } from './displaySelectedMain';
import StyledTooltip from '../../components/utils/styledTooltip';
import { SelectItem } from '../types/select/common';

const refs = (displayedData: SelectItem[]) =>
    displayedData.map(() => createRef<HTMLButtonElement>());

function MultiLevelSelect({
    data: dataArr = [],
    onChange,
    values = {},
    loading,
    id,
    itemsFormattingFunction,
    revert,
    maxWidth,
    maxHeight,
    selectText = 'Filter',
    dropdownClassName,
    targetClassName,
    displayLabel = true,
    secondary,
    dropdownHeight = 400,
    disableGroupMultiSelectedItems,
    initiallyOpened,
    removeGradient,
    hideMainOption,
    disableItemFocus,
}: {
    data: NewTypeSelectData[];
    values?: NewTypeSelectValues;
    onChange?: (value: NewTypeSelectValues) => void;
    loading?: boolean;
    id?: string;
    itemsFormattingFunction?: (str: string, key?: string) => string;
    revert?: boolean;
    maxWidth?: number | string;
    maxHeight?: number;
    dropdownHeight?: number;
    selectText?: string;
    dropdownClassName?: string;
    targetClassName?: string;
    displayLabel?: boolean;
    secondary?: boolean;
    disableGroupMultiSelectedItems?: boolean;
    initiallyOpened?: boolean;
    removeGradient?: boolean;
    hideMainOption?: boolean;
    disableItemFocus?: boolean;
}) {
    const data = useMemo(() => dataArr.reduce((acc, item) => ({ ...acc, ...item }), {}), [dataArr]);
    const { width: viewPortWidth } = useViewportSize();
    const isMobile = isMobileBrowser || viewPortWidth <= 1024;
    const [isOpen, setOpen] = useState(initiallyOpened);
    const [filterMainBtnIsHovered, setFilterMainBtnIsHovered] = useState(false);
    const [selectedMain, setSelectedMain] = useState<string>(Object.keys(data)?.[0]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [rangeInputState, setRangeInputState] = useState<[number, number]>();
    const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);

    const mainDataReshaping = useMemo(
        () => dataArr.map(item => reshapingData(Object.keys(item))) || [],
        [dataArr],
    );

    const subDataReshaping = useMemo<SelectItem[]>(() => {
        const d = data?.[selectedMain]?.data;
        if (!d || !Array.isArray(d) || d.length === 0) return [];
        return reshapingData(d, itemsFormattingFunction, selectedMain) || [];
    }, [data, selectedMain, itemsFormattingFunction]);

    const onDropDownClose = useCallback(() => {
        setSearchValue('');
        setSelectedMain(undefined);
        setOpen(false);
    }, []);

    const handleChange = useCallback(
        (value: NewTypeSelectValues[''], dataKey: string) => {
            if (!(dataKey in values)) {
                onChange({ ...values, [dataKey]: value });
                return;
            }
            const v: NewTypeSelectValues = Object.entries(values).reduce(
                (acc, [key, item]) =>
                    dataKey === key
                        ? ((typeof value?.value === 'undefined' || value.value.length === 0) &&
                              acc) || {
                              ...acc,
                              [key]: value,
                          }
                        : { ...acc, [key]: item },
                {},
            );
            onChange(v);
        },
        [onChange, values],
    );

    const filteredData = useMemo(
        () => filterData(debouncedSearchValue, subDataReshaping),
        [debouncedSearchValue, subDataReshaping],
    );

    const { displayedData, handleScroll } = useScroll({
        data: filteredData,
        isSelectOpen: subDataReshaping.length > 0,
        selectedItemIndex:
            typeof debouncedSearchValue === 'string' && debouncedSearchValue.length > 0 ? -1 : 0,
    });

    const selectedItemRef: React.RefObject<HTMLButtonElement>[] = useMemo(
        () => refs(mainDataReshaping.flat()),
        [mainDataReshaping],
    );
    const selectedSubItemRef: React.RefObject<HTMLButtonElement>[] = useMemo(
        () =>
            displayedData?.length > 0 &&
            refs(displayedData.concat([{ value: 'input ref', label: 'input ref' }]) || []),
        [displayedData],
    );

    // ? Updating Range Value
    useEffect(() => {
        if (!selectedMain || values?.[selectedMain]?.type !== 'range') return;
        setRangeInputState(values[selectedMain].value as [number, number]);
    }, [selectedMain, values]);

    const handleSelectAndMultiSelectChange = useCallback(
        (item: SelectItem, key: string) => {
            const mem = data?.[selectedMain];
            if (onChange && mem) {
                const vals = (values?.[selectedMain]?.value as string[]) || [];
                handleChange(
                    mem.type === 'multiSelect'
                        ? {
                              type: 'multiSelect',
                              value: vals.includes(item.value)
                                  ? vals.filter(v => v !== item.value)
                                  : (mem.limit > vals?.length && [...vals, item.value]) ||
                                    vals.concat([item.value]),
                          }
                        : {
                              type: 'select',
                              value: item.value,
                          },
                    key,
                );
            }
        },
        [data, handleChange, onChange, selectedMain, values],
    );

    const selectAll = useCallback(
        (items: SelectItem[], dataKey: string) => {
            const mem = data?.[selectedMain];
            if (mem.type !== 'multiSelect') return;
            if (onChange && mem) {
                const itemValues = items.map(item => item.value);
                handleChange({ type: mem.type, value: itemValues, isAllSelected: true }, dataKey);
            }
        },
        [data, handleChange, onChange, selectedMain],
    );

    const [isClearAllHovered, setIsClearAllHovered] = useState(false);
    const [isFiltersHovered, setIsFiltersHovered] = useState(false);

    const renderSelectedValues = useMemo(
        () =>
            Object.values(values).length > 0 && (
                <div
                    className={classNames(
                        'flex min-h-[40px] items-center gap-1 border-border-color overflow-auto w-full relative',
                        {
                            'border-r mr-1.5 pr-3 flex-row-reverse': !revert && !isMobile,
                            'border-l ml-1.5 pl-3': revert && !isMobile,
                        },
                    )}
                    style={{
                        maxWidth: !isMobile && (maxWidth ?? 'calc(100vw - 200px)'),
                        height: maxHeight,
                        minWidth: 90,
                    }}
                    onFocusCapture={() => setIsFiltersHovered(true)}
                    onBlurCapture={() => setIsFiltersHovered(false)}
                >
                    {isFiltersHovered && Object.keys(values).length > 1 && (
                        <motion.button
                            type="button"
                            onClick={() => onChange({})}
                            aria-label="Clear filters"
                            className={classNames(
                                'absolute block right-0 top-0 z-10 min-w-[21px] rounded-lg border p-0.5 bg-secondary/bg overflow-hidden text-xxs whitespace-nowrap text-end transition-spacing',
                                {
                                    '!px-1': isClearAllHovered,
                                    'left-0 right-[unset]': revert,
                                },
                            )}
                            animate={{
                                width: isClearAllHovered ? 47 : 21,
                            }}
                            onMouseEnter={() => setIsClearAllHovered(true)}
                            onMouseLeave={() => setIsClearAllHovered(false)}
                        >
                            {isClearAllHovered ? 'Clear All' : <X size={14.8} />}
                        </motion.button>
                    )}
                    <div className="relative flex w-full items-center gap-1 overflow-auto">
                        {Object.entries(values).map(
                            item =>
                                item[1]?.value && (
                                    <RenderSelectedItems
                                        data={data}
                                        key={JSON.stringify(item[1].value)}
                                        handleChange={handleChange}
                                        value={item}
                                        itemsFormattingFunction={e =>
                                            itemsFormattingFunction?.(e, item[0])
                                        }
                                        displayLabel={displayLabel}
                                        secondary={secondary}
                                        isOpen={isOpen}
                                        disableGroupMultiSelectedItems={
                                            isMobile || disableGroupMultiSelectedItems
                                        }
                                    />
                                ),
                        )}
                    </div>
                </div>
            ),
        [
            values,
            revert,
            isMobile,
            maxWidth,
            maxHeight,
            isFiltersHovered,
            isClearAllHovered,
            onChange,
            data,
            handleChange,
            displayLabel,
            secondary,
            isOpen,
            disableGroupMultiSelectedItems,
            itemsFormattingFunction,
        ],
    );

    const valuesCount = useMemo(
        () =>
            Object.values(values).reduce(
                (acc, v) => (v && v.type === 'multiSelect' ? acc + v.value.length : acc + 1),
                0,
            ),
        [values],
    );

    const target = useMemo(
        () => (
            <div
                className={classNames(targetClassName, 'flex gap-1 justify-start items-center', {
                    'justify-end': !revert,
                    'flex-row-reverse justify-end': revert,
                })}
            >
                {!isMobile && renderSelectedValues}
                <button
                    type="button"
                    className={classNames(
                        'p-2 flex items-center gap-2 h-10 delay-[3ms] ease-in-out transition-colors text-sm rounded-full hover:bg-pacific hover:text-white border-transparent justify-self-start text-navy-50',
                        {
                            'hover:bg-[#6938B9]': secondary,
                        },
                    )}
                    style={{
                        backgroundColor:
                            (filterMainBtnIsHovered || isOpen) &&
                            (secondary ? primaryColors.purple : primaryColors.blue),
                        color: filterMainBtnIsHovered || isOpen ? 'white' : undefined,
                    }}
                    onMouseEnter={() => setFilterMainBtnIsHovered(true)}
                    onMouseLeave={() => setFilterMainBtnIsHovered(false)}
                >
                    {loading ? (
                        <Spinner
                            size={16}
                            color={secondary ? primaryColors.purple : primaryColors.blue}
                        />
                    ) : (
                        <FunnelSimple
                            size={24}
                            className="transition-colors delay-[3ms] ease-in-out"
                            color={
                                filterMainBtnIsHovered || isOpen
                                    ? 'white'
                                    : (secondary && primaryColors.purple) || primaryColors.blue
                            }
                        />
                    )}
                    {selectText}
                    {valuesCount > 0 && (
                        <div
                            style={{
                                background:
                                    filterMainBtnIsHovered || isOpen
                                        ? 'white'
                                        : (secondary && primaryColors.purple) || primaryColors.blue,
                                color: !(filterMainBtnIsHovered || isOpen)
                                    ? 'white'
                                    : (secondary && primaryColors.purple) || primaryColors.blue,
                            }}
                            className="rounded-full px-1.5 transition-colors delay-[3ms] ease-in-out"
                        >
                            {valuesCount}
                        </div>
                    )}
                </button>
            </div>
        ),
        [
            filterMainBtnIsHovered,
            isMobile,
            isOpen,
            loading,
            renderSelectedValues,
            revert,
            secondary,
            selectText,
            targetClassName,
            valuesCount,
        ],
    );

    const dropdown = useMemo(
        () => (
            <div
                className={classNames('flex h-full overflow-hidden', dropdownClassName, {
                    'min-w-[400px]': !isMobile && !hideMainOption,
                })}
            >
                {!hideMainOption && (
                    <div className="hideScroll my-2 flex max-h-full !min-w-[135px] max-w-[140px] flex-col items-start overflow-auto border-r border-border-color px-2">
                        {mainDataReshaping?.map((item, index) => (
                            <div
                                key={`section-items-length-${item.length}-${index + 1}`}
                                className={classNames({
                                    'border-b border-border-color': index < dataArr.length - 1,
                                })}
                            >
                                {item.map((key, x) => {
                                    const isDisabled = data[key.value]?.['disabled'];
                                    return (
                                        <StyledTooltip
                                            key={key.value}
                                            label={isDisabled ? 'Coming soon' : ''}
                                            disabled={!isDisabled}
                                            position="bottom"
                                        >
                                            <button
                                                disabled={isDisabled}
                                                type="button"
                                                className={classNames(
                                                    'px-3 py-2.5 hover:bg-pacific-100 disabled:hover:!bg-transparent outline-none text-navy-70 text-sm rounded text-left capitalize w-[120px] whitespace-pre-wrap disabled:text-secondary/70',
                                                    {
                                                        '!bg-pacific-100':
                                                            selectedMain === key.value,
                                                    },
                                                )}
                                                onClick={e => {
                                                    setSelectedMain(key.value);
                                                    const nextData = data[key.value];
                                                    if (nextData.type === 'range') {
                                                        setRangeInputState([
                                                            nextData.data.min,
                                                            nextData.data.max,
                                                        ]);
                                                    }
                                                }}
                                            >
                                                {replaceHtmlEntityNames(
                                                    // ? don't add [[[selectedMain]]]
                                                    itemsFormattingFunction?.(key.value) ||
                                                        key.value.replace('_', ' '),
                                                )}
                                            </button>
                                        </StyledTooltip>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                )}
                <div
                    className="hideScroll my-2 flex min-h-full w-full max-w-[260px] flex-col items-start overflow-auto border-0 border-border-color px-2"
                    style={{
                        minWidth: isMobile && 'calc(100% - 146px)',
                    }}
                >
                    <DisplaySelectedMain
                        data={data}
                        selectedMain={selectedMain}
                        displayedData={displayedData}
                        handleChange={handleChange}
                        handleScroll={handleScroll}
                        handleSelectAndMultiSelectChange={handleSelectAndMultiSelectChange}
                        rangeInputState={rangeInputState}
                        searchValue={searchValue}
                        selectedSubItemRef={selectedSubItemRef}
                        setRangeInputState={setRangeInputState}
                        setSearchValue={setSearchValue}
                        itemsFormattingFunction={e => itemsFormattingFunction?.(e, selectedMain)}
                        selectAll={selectAll}
                        values={values}
                        dropdownHeight={dropdownHeight}
                        disableItemFocus={disableItemFocus}
                    />
                </div>
            </div>
        ),
        [
            data,
            dataArr.length,
            disableItemFocus,
            displayedData,
            dropdownClassName,
            dropdownHeight,
            handleChange,
            handleScroll,
            handleSelectAndMultiSelectChange,
            hideMainOption,
            isMobile,
            itemsFormattingFunction,
            mainDataReshaping,
            rangeInputState,
            searchValue,
            selectAll,
            selectedMain,
            selectedSubItemRef,
            values,
        ],
    );

    if (isMobile) {
        return (
            <div className="relative">
                <button type="button" onClick={() => setOpen(true)} disabled={loading}>
                    {target}
                </button>
                <Drawer
                    opened={isOpen}
                    onClose={onDropDownClose}
                    position="bottom"
                    classNames={{
                        body: '!p-0 h-full bg-transparent overflow-hidden',
                        header: '!hidden',
                        content:
                            '!rounded-t-2xl backdrop-blur-xl gradient-bg overflow-hidden w-full absolute right-0 h-full',
                    }}
                    styles={{
                        content: {
                            maxWidth: viewPortWidth > 765 && 'calc(100vw - 69px)',
                            minWidth: viewPortWidth > 765 && 'calc(100vw - 69px)',
                        },
                    }}
                    closeButtonProps={{ className: 'hidden' }}
                    zIndex={999}
                >
                    <button
                        type="button"
                        onClick={onDropDownClose}
                        className="flex h-10 w-full items-center justify-center"
                        aria-label="close dropdown"
                    >
                        <CaretDown size={22} />
                    </button>

                    <motion.div
                        className={classNames(
                            targetClassName,
                            'flex gap-1 justify-start items-center overflow-auto max-w-full',
                            {
                                'px-3': isMobile,
                            },
                        )}
                        animate={{
                            height: Object.values(values).length > 0 ? 40 : 0,
                        }}
                    >
                        Selected: {renderSelectedValues}
                    </motion.div>
                    {dropdown}
                </Drawer>
            </div>
        );
    }
    return (
        <Menu
            id={id}
            onOpen={() => {
                setSelectedMain(Object.keys(data)?.[0]);
                setOpen(true);
                selectedItemRef?.[0]?.current?.focus();
            }}
            disabled={loading}
            onClose={onDropDownClose}
            opened={isOpen}
            onChange={setOpen}
            position={revert ? 'bottom-start' : 'bottom-end'}
            classNames={{
                dropdown: classNames(
                    'absolute rounded-lg w-[390px] opacity-0 !z-[401] backdrop-blur-xl border-t border-l border-0 border-border-color flex h-fit',
                    { 'gradient-bg': !removeGradient },
                    dropdownClassName,
                ),
            }}
            styles={{
                dropdown: {
                    boxShadow: '8px 8px 16px var(--shadow-color)',
                    height: dropdownHeight,
                },
            }}
        >
            <Menu.Target>{target}</Menu.Target>
            <Menu.Dropdown>{dropdown}</Menu.Dropdown>
        </Menu>
    );
}

export default MultiLevelSelect;
