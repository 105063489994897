import { dateSubtraction, dateSubtractionMonthly } from '../utils/dateHelpers';

const durations = [
    // ? Yesterday + 6 days = Last 7 days
    { value: dateSubtraction(6, 'days'), label: 'Last 7 Days' },
    { value: dateSubtraction(1, 'M'), label: 'Last 30 Days' },
    { value: dateSubtraction(3, 'M'), label: 'Last 3 Months' },
    { value: dateSubtraction(6, 'M'), label: 'Last 6 Months' },
    { value: dateSubtraction(12, 'M'), label: 'Last 12 Months' },
];

const monthlyDurations = [
    { value: dateSubtractionMonthly(1, 'M'), label: 'Last Month' },
    { value: dateSubtractionMonthly(3, 'M'), label: 'Last 3 Months' },
    { value: dateSubtractionMonthly(6, 'M'), label: 'Last 6 Months' },
    { value: dateSubtractionMonthly(12, 'M'), label: 'Last 12 Months' },
];

export { durations, monthlyDurations };
