import { colorsScheme } from '@/constants/colors';
import { BarChartOptionsPlugins, LineChartOptionsPlugins } from './types';

const DEFAULT_CHART_TENSION = 0.3;
const DEFAULT_CHART_POINT_RADIUS = 0;

const grid = {
    // dash: [3, 3],
    // color: colorsScheme[theme]['primary/bg'],
    display: false,
};

const defaultChartTextColor = '#B1B5C1';

const defaultTooltip: LineChartOptionsPlugins['tooltip'] = {
    position: 'nearest',
    footerAlign: 'right',
    footerFont: {
        size: 11,
        weight: 'normal',
    },
    usePointStyle: true,
    intersect: false,
    borderWidth: 2,
    padding: 9,
};

const defaultTitle: LineChartOptionsPlugins['title'] = {
    color: '#B1B5C1',
    font: {
        size: 14,
    },
};

const defaultTooltipBar: BarChartOptionsPlugins['tooltip'] = {
    position: 'nearest',
    footerAlign: 'right',
    footerFont: {
        size: 11,
        weight: 'normal',
    },
    usePointStyle: true,
    intersect: false,
    borderWidth: 2,
    padding: 9,
};

const defaultTitleBar: BarChartOptionsPlugins['title'] = {
    color: '#B1B5C1',
    font: {
        size: 14,
    },
};

export {
    DEFAULT_CHART_POINT_RADIUS,
    DEFAULT_CHART_TENSION,
    grid,
    defaultTooltip,
    defaultTitle,
    defaultTooltipBar,
    defaultTitleBar,
    defaultChartTextColor,
};
