import React from 'react';
import cx from 'classnames';
import Color from 'color';
import moment from 'moment';
import { CaretDown, CaretUp } from 'phosphor-react';

type DateInput = string | Date | moment.Moment;

interface GetProgressPropsByDateParams {
    startDate: DateInput;
    endDate: DateInput;
    currentDate: DateInput;
}

export const getProgressPropsByDate = ({
    currentDate,
    endDate,
    startDate,
}: GetProgressPropsByDateParams) => {
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).endOf('day');
    const current = moment(currentDate).startOf('day');

    const totalDays = end.diff(start, 'days');
    const daysPassed = current.diff(start, 'days');

    return {
        min: 0,
        max: totalDays,
        value: daysPassed,
    };
};

interface Labels {
    min?: number;
    max?: number;
    value?: number;
    filled?: string;
    empty?: string;
    unit?: string;
}

export interface ProgressBarProps {
    color?: string;
    min?: number;
    max: number;
    value: number;
    className?: string;
    barClassName?: string;
    innerBarClassName?: string;
    height?: number;
    width?: number | string;
    labels?: Labels;
    caretPosition?: 'up' | 'down' | 'none';
    caretCalculatedBy?: 'label' | 'value';
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    className,
    barClassName,
    innerBarClassName,
    max,
    min = 0,
    value,
    color = '#1f1f1f',
    height = 24,
    width = '100%',
    labels = {},
    caretCalculatedBy = 'value',
    caretPosition = 'up',
}) => {
    const barWidth = `${((value - min) / (max - min)) * 100}%`;

    const caretWidth =
        caretCalculatedBy === 'value'
            ? `${((value - min) / (max - min)) * 100 - 1.6}%`
            : `${((labels.value - labels.min) / (labels.max - labels.min)) * 100 - 1.6}%`;

    const isBottomLabelActive = labels.min || labels.max || labels.value;
    return (
        <div style={{ width }} className={cx(className)}>
            <div
                className={cx('rounded-full flex', barClassName)}
                style={{
                    width,
                    height,
                    backgroundColor: Color(color).alpha(0.2).string(),
                }}
            >
                <div
                    className={cx(
                        'h-full rounded-full flex items-center justify-center text-xs text-white',
                        innerBarClassName,
                    )}
                    style={{
                        width: barWidth,
                        backgroundColor: color,
                    }}
                >
                    {labels.filled}
                </div>
                <div className="flex grow items-center justify-center text-xs">{labels.empty}</div>
            </div>
            {isBottomLabelActive && (
                <div className="relative mt-1 h-1">
                    <div className="absolute left-0 text-xs text-gray-dark">
                        {labels.min}
                        {labels.unit ?? '%'}
                    </div>
                    <div
                        className="absolute flex flex-col"
                        style={{
                            left: caretWidth,
                            // ? 32 is the height of the caret + label
                            top:
                                caretPosition === 'down'
                                    ? -height - 32
                                    : caretPosition === 'up' && -8,
                        }}
                    >
                        {caretPosition === 'up' && (
                            <CaretUp weight="fill" size={16} className="text-gray-950" />
                        )}
                        <div className="text-xs text-gray-dark">
                            {labels.value}
                            {labels.unit ?? '%'}
                        </div>
                        {caretPosition === 'down' && (
                            <CaretDown weight="fill" size={16} className="text-gray-950" />
                        )}
                    </div>
                    <div className="absolute right-0 text-xs text-gray-dark">
                        {labels.max}
                        {labels.unit ?? '%'}
                    </div>
                </div>
            )}
        </div>
    );
};
