import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import moment from 'moment';

import { grayScale } from '../../constants/colors';
import { Legends } from './Legends';
import { Dataset, DatasetValue } from '../types/timeSeriesChart';
import { drawTargetPriceLine, isValidDate, onMouseMove, renderDataset } from './chartUtils';

type AnnotationPosition = 'start' | 'middle' | 'end';

interface TimeseriesChartProps {
    datasets: Dataset[];
    labels: Date[];
    width?: number;
    height?: number;
    formatYValue?: (value: number) => string;
    linearGradientColor?: string;
    showLegends?: boolean;
    targetProjectionDate?: Date;
    targetPrice?: number;
    targetPriceAnnotation?: string;
    targetPriceAnnotationPosition?: AnnotationPosition;
    rotateXLabels?: boolean;
    enableAreaSelect?: boolean;
    maxZoomLevels?: number;
    hideYAxis?: boolean;
}

export const TimeseriesChart: FC<TimeseriesChartProps> = ({
    datasets,
    labels,
    width = 800,
    height = 400,
    formatYValue,
    linearGradientColor,
    showLegends,
    targetProjectionDate,
    targetPrice,
    targetPriceAnnotation = '',
    targetPriceAnnotationPosition = 'start',
    rotateXLabels = false,
    enableAreaSelect = false,
    maxZoomLevels = 5,
    hideYAxis = false,
}) => {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const [zoomLevel, setZoomLevel] = useState(0);
    const [zoomDomain, setZoomDomain] = useState<[Date, Date] | null>(null);

    const margin = useMemo(
        () => ({
            top: 20,
            right: 30,
            bottom: rotateXLabels ? 50 : 30,
            left: 40,
        }),
        [rotateXLabels],
    );
    const adjustedWidth = width - margin.left - margin.right;
    const adjustedHeight = height - margin.top - margin.bottom;

    useEffect(() => {
        if (!datasets || datasets.length === 0) return;

        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove(); // Clear SVG content before redrawing

        const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

        // Define scales
        const xScale = d3
            .scaleTime()
            .domain(
                zoomDomain || [
                    d3.min(datasets, dataset => d3.min(dataset.values, d => moment(d.x).toDate())),
                    d3.max(datasets, dataset => d3.max(dataset.values, d => moment(d.x).toDate())),
                ],
            )
            .range([0, adjustedWidth]);

        const maxYValue = d3.max(datasets, dataset => d3.max(dataset.values, d => d.y));
        const yScale = d3.scaleLinear().domain([0, maxYValue]).range([adjustedHeight, 0]);

        // Calculate top and middle values for y-axis
        const middleYValue = maxYValue / 2;

        // y-axis
        if (!hideYAxis) {
            g.append('g')
                .call(
                    d3
                        .axisLeft(yScale)
                        .tickValues([middleYValue, maxYValue])
                        .tickFormat((d, i) => {
                            const formattedValue = formatYValue ? formatYValue(d as number) : d;
                            return typeof formattedValue === 'string'
                                ? formattedValue
                                : String(formattedValue);
                        }),
                )
                .call(() => g.select('.domain').remove())
                .call(() => g.selectAll('.tick line').remove())
                .call(() => g.selectAll('.tick text').attr('fill', grayScale[60]));
        }

        // x-axis
        const xAxis = g
            .append('g')
            .attr('transform', `translate(0,${adjustedHeight})`)
            .call(
                d3
                    .axisBottom(xScale)
                    .tickValues(labels)
                    .tickSizeOuter(0)
                    .tickFormat(d3.timeFormat('%b %d')),
            );

        if (rotateXLabels) {
            xAxis
                .selectAll('text')
                .attr('transform', 'rotate(-45)')
                .style('text-anchor', 'end')
                .attr('dx', '-.8em')
                .attr('dy', '.15em');
        }

        xAxis.select('.domain').attr('stroke', grayScale[60]);
        xAxis.selectAll('.tick text').attr('fill', grayScale[60]);
        xAxis.selectAll('.tick line').remove();

        // chart content
        const contentGroup = g.append('g');

        if (enableAreaSelect && zoomLevel && zoomDomain) {
            // clipped content
            contentGroup.attr('clip-path', 'url(#chart-area-clip)');

            svg.append('defs')
                .append('clipPath')
                .attr('id', 'chart-area-clip')
                .append('rect')
                .attr('width', adjustedWidth)
                .attr('height', height)
                .attr('y', -margin.top);

            // clip x-axis
            xAxis.selectAll('.tick text').attr('visibility', (d, i, nodes) => {
                const xPos = xScale(d as any);
                const textElement = nodes[i] as SVGTextElement;
                const textWidth = textElement.getBBox().width;

                if (xPos - textWidth / 2 < -15 || xPos + textWidth / 2 > adjustedWidth + 15) {
                    return 'hidden';
                }

                return 'visible';
            });
        }

        // Styling
        contentGroup.select('.domain').attr('stroke', grayScale[60]); // Change axis line color
        contentGroup.selectAll('.tick text').attr('fill', grayScale[60]); // Change tick text color
        contentGroup.selectAll('.tick line').remove(); // Remove tick lines

        // Draw lines
        const lineGenerator = d3
            .line<DatasetValue>()
            .x(d => xScale(moment(d.x).toDate()))
            .y(d => yScale(d.y))
            .curve(d3.curveMonotoneX); // This makes the line smooth

        datasets.forEach(dataset =>
            renderDataset({
                dataset,
                lineGenerator,
                adjustedHeight,
                xScale,
                yScale,
                targetProjectionDate,
                svgGroup: contentGroup,
            }),
        );

        // draw vertical line if we have targetProjectionDate
        if (isValidDate(targetProjectionDate)) {
            contentGroup
                .append('line')
                .attr('class', 'verticalLine')
                .attr('x1', xScale(targetProjectionDate))
                .attr('y1', 0)
                .attr('x2', xScale(targetProjectionDate))
                .attr('y2', adjustedHeight)
                .style('stroke', grayScale[60]);
        }

        // draw horizontal line if we have a valid targetPrice
        if (typeof targetPrice === 'number' && !isNaN(targetPrice)) {
            drawTargetPriceLine({
                targetPrice,
                targetPriceAnnotation,
                targetPriceAnnotationPosition,
                adjustedWidth,
                yScale,
                svgGroup: contentGroup,
            });
        }

        // Vertical line
        const verticalLine = contentGroup
            .append('line')
            .attr('class', 'verticalLine')
            .attr('y1', 0)
            .attr('y2', adjustedHeight)
            .style('stroke', grayScale[40])
            .style('display', 'none');

        // Dots group
        const dots = contentGroup
            .selectAll('.dots')
            .data(datasets)
            .enter()
            .append('circle')
            .attr('class', 'dot')
            .attr('r', 2)
            .style('fill', grayScale[100])
            .style('display', 'none');

        // Tooltip setup
        const tooltipGroup = g.append('g').attr('class', 'tooltip-group').style('display', 'none');
        // Tooltip background
        tooltipGroup
            .append('rect')
            .attr('width', 120) // Initial guess, adjust based on content
            .attr('height', 60) // Adjust based on content
            .attr('fill', 'none')
            .attr('rx', 4) // Rounded corners
            .attr('ry', 4);

        // Tooltip text will be added dynamically
        tooltipGroup
            .append('text')
            .attr('class', 'tooltip-text')
            .attr('x', 5) // A bit of padding from the rectangle's edge
            .attr('y', 20) // Adjust based on how you want to position the text inside the rectangle
            .attr('fill', grayScale[100]);

        // Define glow filter start
        const defs = svg.append('defs');
        const filter = defs.append('filter').attr('id', 'glow');

        // The intensity and spread of the glow can be adjusted by changing the stdDeviation attribute in the feGaussianBlur element.
        filter
            .append('feGaussianBlur')
            .attr('stdDeviation', '4') // This value is the intensity of the glow
            .attr('result', 'coloredBlur');

        const feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode').attr('in', 'coloredBlur');
        feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
        // Define glow filter end

        // Define the gradient for the area fill - start
        const gradientAreaDefs = svg.append('defs');
        const gradient = gradientAreaDefs
            .append('linearGradient')
            .attr('id', 'area-gradient') // Unique ID for the gradient
            .attr('gradientUnits', 'userSpaceOnUse') // Use the entire SVG for gradient coordinates
            .attr('x1', '0%')
            .attr('y1', '0%')
            .attr('x2', '0%')
            .attr('y2', '90%');

        // Define the colors of the gradient from start to end
        gradient
            .append('stop')
            .attr('offset', '0%')
            .attr('stop-color', linearGradientColor) // Color at the top
            .attr('stop-opacity', 0.1); // More opaque at the top

        gradient
            .append('stop')
            .attr('offset', '90%')
            .attr('stop-color', linearGradientColor) // Fade to white towards the bottom
            .attr('stop-opacity', 0); // Fully transparent at the bottom
        // Define the gradient for the area fill - end

        if (enableAreaSelect) {
            // add brush
            const brush = d3
                .brushX()
                .extent([
                    [0, 0],
                    [adjustedWidth, adjustedHeight],
                ])
                .on('end', event => {
                    if (!event.selection) {
                        return;
                    }

                    if (zoomLevel >= maxZoomLevels) {
                        g.select<SVGGElement>('.brush').call(brush.move, null);
                        return;
                    }

                    const [x0, x1] = event.selection as [number, number];
                    const selectedDomain: [Date, Date] = [xScale.invert(x0), xScale.invert(x1)];

                    setZoomDomain(selectedDomain);
                    setZoomLevel(prevLevel => prevLevel + 1);

                    // clear the brush
                    g.select<SVGGElement>('.brush').call(brush.move, null);
                });

            g.attr('class', 'brush').call(brush);

            // reset zoom on double click
            svg.on('dblclick', () => {
                setZoomDomain(null);
                setZoomLevel(0);
            });
        } else {
            g.append('rect')
                .attr('class', 'overlay')
                .attr('width', adjustedWidth)
                .attr('height', adjustedHeight)
                .attr('opacity', 0)
                .attr('transform', `translate(0,0)`);
        }

        g.on('mouseover', () => {
            verticalLine.style('display', null);
            dots.style('display', null);
            tooltipGroup.style('display', null);
        })
            .on('mouseout', () => {
                verticalLine.style('display', 'none');
                dots.style('display', 'none');
                tooltipGroup.style('display', 'none');
            })
            .on('mousemove', e =>
                onMouseMove(e, {
                    adjustedWidth,
                    datasets,
                    dots,
                    tooltipGroup,
                    verticalLine,
                    xScale,
                    yScale,
                }),
            );
    }, [
        datasets,
        width,
        height,
        labels,
        formatYValue,
        adjustedHeight,
        adjustedWidth,
        margin,
        linearGradientColor,
        targetProjectionDate,
        targetPrice,
        targetPriceAnnotation,
        targetPriceAnnotationPosition,
        rotateXLabels,
        zoomDomain,
        zoomLevel,
        maxZoomLevels,
        enableAreaSelect,
        hideYAxis,
    ]);

    return (
        <div id="timeseries-chart-container" style={{ width: '100%', maxWidth: '100%' }}>
            <svg
                ref={svgRef}
                width="100%"
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                preserveAspectRatio="xMidYMid meet"
            />
            {showLegends && <Legends datasets={datasets} />}
        </div>
    );
};
