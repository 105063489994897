import cx from 'classnames';
import { __InputStylesNames, Combobox, ComboboxProps, Skeleton, useCombobox } from '@mantine/core';
import React, { useMemo, useState } from 'react';
import { X } from 'phosphor-react';
import { SelectsDataType } from '../types/select/common';
import Dropdown from './dropdown';
import SelectedItemBox from './selectedItemBox';
import Caret from '../caret';
import SelectLabelWrapper from './selectLabelWrapper';
import { CustomTextType } from '../text';
import StyledTooltip from '../../components/utils/styledTooltip';
import { getKeys } from '../util/selects';

type CXS = {
    select?: ComboboxProps['classNames'];
    input?: Partial<Record<__InputStylesNames, string>>;
};
const MultiSelect = React.forwardRef(
    (
        {
            data = [],
            value = [],
            onChange,
            classNames,
            loading,
            preventSkeleton,
            width = 200,
            placeholder,
            clearable = true,
            slimUI = false,
            className,
            id,
            disabled,
            check = true,
            label,
            textType,
            limit,
            formattingFunction,
            leftSection,
            cleanDesign,
            onClick,
            gradientBG,
            getCreateLabel,
            dropdownClassName,
            onCreate,
            creatable,
            withinPortal = true,
        }: {
            data: SelectsDataType[];
            onChange: (str: string[]) => void;
            formattingFunction?: (str: string) => string;
            value: string[];
            slimUI?: boolean;
            classNames?: CXS;
            loading?: boolean;
            preventSkeleton?: boolean;
            label?: string | JSX.Element;
            textType?: CustomTextType;
            clearable?: boolean;
            check?: boolean;
            disabled?: boolean;
            width?: number;
            className?: string;
            placeholder?: string;
            id?: string;
            limit?: number;
            leftSection?: JSX.Element;
            cleanDesign?: boolean;
            gradientBG?: boolean;
            withinPortal?: boolean;
            onClick?: () => void;
            dropdownClassName?: string;
            creatable?: boolean;
            getCreateLabel?: (query: string) => string;
            onCreate?: (query: string) => string;
        },
        ref: React.Ref<HTMLInputElement>,
    ) => {
        const [searchValue, setSearchValue] = useState<string>();
        const isClearable = value.length > 0 && clearable;

        const combobox = useCombobox({
            onDropdownClose: () => {
                setSearchValue(undefined);
                combobox.resetSelectedOption();
            },
            scrollBehavior: 'auto',
        });

        const keysValue = useMemo(
            () => (typeof data?.[0] === 'string' ? {} : getKeys(data)),
            [data],
        );

        if (loading && !preventSkeleton) {
            return <Skeleton height={40} width={width} />;
        }
        return (
            <SelectLabelWrapper
                label={label}
                textType={textType}
                cleanDesign={cleanDesign}
                disabled={disabled}
            >
                <Combobox
                    store={combobox}
                    onOptionSubmit={val => {
                        if ((limit && val.length >= limit) || value.includes(val)) return;
                        onChange(value.concat(val));
                        combobox.closeDropdown();
                    }}
                    classNames={classNames?.select}
                    width={width}
                    withinPortal={withinPortal}
                    onOpen={onClick}
                    transitionProps={{ duration: 300, transition: 'pop' }}
                >
                    <Combobox.Target ref={ref}>
                        <div
                            className={cx(
                                'min-h-[40px] h-fit group border-border-color bg-secondary/5 px-3 border rounded w-full flex gap-2 items-center text-navy-50',
                                className,
                                {
                                    'py-2': value.length > 0,
                                    '!h-fit': !slimUI && combobox.dropdownOpened,
                                    'border-navy-10': combobox.dropdownOpened,
                                    'gradient-bg-hex': gradientBG,
                                    '!text-navy-15': disabled,
                                    '!border-none !bg-transparent': cleanDesign,
                                },
                            )}
                            style={{ width }}
                            onClickCapture={() => data.length > 0 && combobox.openDropdown()}
                        >
                            <div
                                className={cx(
                                    'flex items-center gap-1 flex-wrap h-fit',
                                    classNames?.input?.input,
                                    {
                                        'flex-wrap h-fit': !slimUI,
                                    },
                                )}
                                style={{ width: width - 50 }}
                            >
                                {value.length > 0 &&
                                    value.map(item => {
                                        const displayValue = keysValue?.[item]?.label ?? item;
                                        return (
                                            <StyledTooltip
                                                key={`multi-selected_${displayValue}`}
                                                label={keysValue?.[item]?.tooltipLabel}
                                                disabled={!keysValue?.[item]?.tooltipLabel}
                                                w={200}
                                            >
                                                <SelectedItemBox
                                                    onRemove={() =>
                                                        onChange(value.filter(sav => sav !== item))
                                                    }
                                                >
                                                    {formattingFunction?.(displayValue) ??
                                                        displayValue}
                                                </SelectedItemBox>
                                            </StyledTooltip>
                                        );
                                    })}
                                {((value.length > 0 && combobox.dropdownOpened) ||
                                    value.length === 0) && (
                                    <Combobox.Search
                                        id={id}
                                        className={cx('capitalize', className)}
                                        classNames={{
                                            ...(classNames?.input ?? {}),
                                            input: cx(
                                                classNames?.input?.input,
                                                'cursor-pointer !pl-0 z-10 h-10 !border-none disabled:!text-inherit !bg-transparent w-full placeholder:text-sm whitespace-nowrap !overflow-hidden text-ellipsis',
                                                {
                                                    '!border-white': combobox.dropdownOpened,
                                                    '!pl-6': leftSection,
                                                    '!min-h-[40px]': !slimUI,
                                                },
                                            ),
                                            wrapper: cx(
                                                classNames?.input?.wrapper,
                                                '!border-none cursor-pointer z-10 !bg-transparent !w-min !border !rounded placeholder:text-sm w-full whitespace-nowrap !overflow-hidden text-ellipsis disabled:!text-navy-15',
                                                {
                                                    '!bg-transparent': cleanDesign,
                                                },
                                            ),
                                            section: cx({ '!-ml-1.5': leftSection }),
                                        }}
                                        styles={{
                                            wrapper: { width: width - 58 },
                                            input: { width: width - 58 },
                                        }}
                                        value={searchValue}
                                        onChange={event => {
                                            setSearchValue(event.currentTarget.value);
                                        }}
                                        disabled={disabled}
                                        leftSection={leftSection}
                                        placeholder={value.length === 0 ? placeholder : ''}
                                        autoComplete="off"
                                        name={`multiselect input ${id}`}
                                    />
                                )}
                            </div>
                            {clearable && value?.length > 0 ? (
                                <button
                                    type="button"
                                    aria-label="clear select"
                                    onClick={() => onChange([])}
                                >
                                    <X size={16} />
                                </button>
                            ) : (
                                <Caret
                                    open={combobox.dropdownOpened}
                                    className={cx({
                                        'caret-icon': cleanDesign,
                                        '!text-navy-15': disabled,
                                        '!text-navy-50': !disabled,
                                        'hidden group-hover:visible': isClearable,
                                    })}
                                />
                            )}
                        </div>
                    </Combobox.Target>
                    <Dropdown
                        width={width}
                        data={data}
                        limit={limit}
                        value={value}
                        checkIcon={check}
                        formattingFunction={formattingFunction}
                        gradientBG={gradientBG}
                        creatable={creatable}
                        getCreateLabel={getCreateLabel}
                        onCreate={onCreate}
                        dropdownClassName={dropdownClassName}
                        searchValue={searchValue}
                    />
                </Combobox>
            </SelectLabelWrapper>
        );
    },
);

export default MultiSelect;
