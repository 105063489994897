import styled from '@emotion/styled';
import { Menu } from '@mantine/core';
import { CaretDown } from 'phosphor-react';
import React, { useState } from 'react';
import cx from 'classnames';

const DropdownTarget = styled.div`
    .caret-icon {
        visibility: hidden;
    }
    :hover .caret-icon {
        visibility: visible !important;
    }
`;

export interface DropdownOption<TValue = string> {
    label: string;
    value: TValue;
    className?: string;
}

interface DropdownProps<TValue = string> {
    options: DropdownOption<TValue>[];
    value: TValue;
    onChange(value: TValue): void;
    labelClassName?: string;
}

export const Dropdown = <TValue extends string = string>({
    options,
    value,
    onChange,
    labelClassName,
}: DropdownProps<TValue>) => {
    const [opened, setOpened] = useState(false);

    const selectedOption = options?.find(option => option.value === value);

    return (
        <Menu opened={opened} onChange={setOpened} position="bottom-start">
            <Menu.Target>
                <DropdownTarget
                    className={cx(
                        'flex items-center gap-1 cursor-pointer text-sm text-primary/70 hover:text-primary',
                        labelClassName,
                    )}
                >
                    <span>{selectedOption?.label}</span>
                    <CaretDown
                        className={cx('transition-transform caret-icon', {
                            'transform rotate-180 !visible': opened,
                        })}
                    />
                </DropdownTarget>
            </Menu.Target>
            <Menu.Dropdown>
                {options?.map(option => (
                    <Menu.Item
                        key={option.value}
                        className={cx({
                            'bg-secondary text-primary': option.value === value,
                        })}
                        onClick={() => onChange(option.value)}
                    >
                        {option.label}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};
